<template>
  <div>
    <h2>宣传视频</h2>
    <div class="df-wrap f-sb">
      <el-card
        shadow="hover"
        class="box-card hover"
        v-for="(item, index) in videolist"
        :key="index"
        style="width: 30.3%; cursor: pointer"
      >
        <div @click="goVideo(item)">
          <img
            style="height: 140px; width: 100%; object-fit: cover"
            :src="'/com/file/fileDownLoad?filePath=' + item.videoImage"
            alt
            :onerror="smallImgError"
          />
          <div
            class="fp"
            style="text-align: center"
            v-html="item.videoDetail"
          ></div>
        </div>
      </el-card>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="total <= 9"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleChange"
        :current-page.sync="current"
        :page-size="size"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getVideo } from "@/api";

export default {
  name: "video",
  data() {
    return {
      videolist: [], //宣传视频列表
      current: 1,
      total: 0,
      size: 9,
    };
  },
  mounted() {
    this.getVideo();
  },
  methods: {
    handleChange() {},
    // 去视频
    goVideo(item) {
      this.$router.push({
        path: "/information/video/videodetail",
        query: {
          id: item.id,
        },
      });
    },
    async getVideo() {
      let res = await getVideo({
        size: this.size,
        current: this.current,
        type: 2,
      });
      if (res.code == 1) {
        this.videolist = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  text-align: center;
}
/deep/.el-card {
  margin-bottom: 10px;
}
.fp {
  // color: aqua;
  background-color: #dcefe6;
}
</style>